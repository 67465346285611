/* eslint-disable react/no-unknown-property */
import React from 'react'
import { useTranslation } from "react-i18next";
import style from "./Home.module.css"


import {  Card, CardActionArea, CardActions, CardContent, IconButton, Stack, Typography } from '@mui/material';

import FactoryIcon from '@mui/icons-material/Factory';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import ApartmentIcon from '@mui/icons-material/Apartment';

import agriPic from "../../Assets/Pic/agriCover.jpeg"
import developPic from "../../Assets/Pic/developCover.jpeg"
import industryPic from "../../Assets/Pic/IndustryCover.jpeg"


export default function Department() {

    const { t } = useTranslation();

    let cardArr = [
      {id : 1 , icons : <ApartmentIcon/> , title : t("homePage.development") , imgUrl : developPic , content : t("homePage.descDevelop") , exampleModel : "exampleModel1" },
      {id : 2 , icons : <FactoryIcon/> ,  title : t("homePage.industry") , imgUrl : industryPic , content : t("homePage.descIndustry") , exampleModel : "exampleModel2" },
      {id : 3 , icons : <AgricultureIcon/>  , title : t("homePage.agri") , imgUrl : agriPic , content :  t("homePage.descAgri"), exampleModel : "exampleModel3"  }
    ]

  return <>
        <div className="my-5">
        <h1 className={`text-center fw-bold  ${style.header}`}>
        {t("homePage.depart")}
      </h1>


<Stack direction={'row'} sx={{display : {xs :"block" , md : "flex"}}} gap={5} alignItems={"center"} className=' mt-5' >

        {
          cardArr.map((item)=>{
            return <>
<Card  className=' p-2 mb-3' key={item.id}>
<CardActionArea >

<IconButton >
  {item.icons}
</IconButton>
  <CardContent className='text-center'> 
    <Typography gutterBottom variant="p" className='text-center fw-bolder' component="h2">
      {item.title}
    </Typography>
    <Typography variant="p" sx={{ color: 'text.secondary' }} className=' h6 text-center'>
      {item.content}
    </Typography>
  </CardContent>
</CardActionArea>
<CardActions className=' d-flex justify-content-end align-items-center'>
  <button className={` ${style.colorMore} btn  fw-bolder  `} data-bs-toggle="modal" data-bs-target={`#${item.exampleModel}`} >
    {t("homePage.more")} 
  </button>
</CardActions>
</Card>
            </>
          })
        }

</Stack>
    
  
{
  cardArr.map((item)=>{
    return <>
    <div className="modal fade mt-5" id={`${item.exampleModel}`} tabindex="-1"  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header d-flex flex-column">
        <picture>
          <img src={item.imgUrl} alt={`${item.title}`} className=' img-fluid rounded-3 mb-3'/>
        </picture>
        <h1 className="modal-title fs-4" id="exampleModalLabel">{item.title}</h1>
      </div>
      <div className="modal-body">
        <p className='h5'>{item.content}</p>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn fw-bolder btn-danger" data-bs-dismiss="modal">{t("homePage.close")}</button>
      </div>
    </div>
  </div>
</div>
    
    </>
  })
}
 





      </div>
  </>
}
{/* <Card  className=' p-2' key={item.id}>
<CardActionArea>

<IconButton>
  {item.icons}
</IconButton>
  <CardContent className='text-center'> 
    <Typography gutterBottom variant="h5" className='text-center fw-bolder' component="h2">
      {item.title}
    </Typography>
    <Typography variant="body1" sx={{ color: 'text.secondary' }} className='text-center'>
      {item.content}
    </Typography>
  </CardContent>
</CardActionArea>
<CardActions className=' d-flex justify-content-end align-items-center'>
  <Button size="large" className={` ${style.colorMore} fw-bolder `} data-bs-toggle="modal" data-bs-target={`#${item.exampleModel}`} >
    {t("homePage.more")} 
  </Button>
</CardActions>
</Card> */}



//  <div class="card mb-3">
//   <div class="row g-0">
//   <div class="col-md-5">
//     <img src={item.imgUrl} className="img-fluid rounded" alt={item.title}/>
//   </div>
//   <div class="col-md-7 d-flex align-items-center">
//     <div class="card-body">
//       <h1 class="card-title px-3">{item.title}</h1>
//     </div>
//   </div>

//   <Button size="large" className={` ${style.colorMore} fw-bolder `} data-bs-toggle="modal" data-bs-target={`#${item.exampleModel}`} >
//   {t("homePage.more")} 
// </Button>
// </div>
// </div>