import React, { useState } from "react";
import style from "./Navbar.module.css";
import { Link } from "react-router-dom";
import englishLogo from "../../Assets/logo/holding.png";
import { useTranslation } from "react-i18next";

export default function Navbar() {
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const { t } = useTranslation();

  const [imgUrl , setImgUrl] = useState(englishLogo)

  function getArabic(){
    setImgUrl(englishLogo);
  }

  function getEnglish(){
    setImgUrl(englishLogo);
  }

  function allArabic(){
    changeLanguage("ar");
    getArabic();
  }

  function allEnglish(){
    changeLanguage("en");
    getEnglish();
  }
  return (
    <>
      <nav className={`navbar navbar-expand-lg ${style.nav} position-fixed start-0 end-0 top-0 z-3 py-3`}>
        <div className={` ${style.navWid} container-fluid`}>

          <a className="navbar-brand text-white" href="/home">
          <img src={imgUrl} className={`${style.logo}`}/>
          </a>
          <button
            className={` ${style.btnNav} navbar-toggler`}
            type="button"
            data-bs-toggle="collapse" 
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
          <i className="fa-solid fa-bars-staggered text-white p-1"></i>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className={` ${style.ulStyle} navbar-nav ms-auto   mb-2 mb-lg-0`} >
              <li className="nav-item">
                <Link
                className={`${style.linkStyle} text-center mx-auto nav-link `}
                  aria-current="page"
                  to="/"
                >
                {t("home")}
                </Link>
              </li>

              <li className="nav-item">
              <a className={`${style.linkStyle} mx-auto px-3 nav-link text-center`} href="#about">
              {t("about")}
              </a>
            </li>

      
              <li className="nav-item">
                <a
                className={`${style.linkStyle} mx-auto  nav-link text-center`}
                  aria-current="page"
                  href="#contactus"
                >
                {t("contact")}
                </a>
              </li>
            
            </ul>

            <div className={`${style.line} `}>

            </div>

            <div className={`d-flex justify-content-center ${style.divLang} rounded-5 `}>
            <button
            className="btn border-0"
            onClick={() => allEnglish() }
            
            > 
            <h5 className={`${style.lang} text-white mt-2 `}>ENG</h5>
          </button>
          <button
          className="btn border-0"
          onClick={() => allArabic()}>
          <h5 className={`${style.lang} text-white mt-2 `}>{t("ar")}</h5>
          </button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
