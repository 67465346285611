import React from 'react'

import style from "./Footer.module.css"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

export default function Footer() {

  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const { t } = useTranslation();

  return (
    <>
    <footer className={` mt-5 py-3 `}>
    <div className={`${style.container}`}>

   
    <div className={`row`}>


      <div className='col-md-4'>
      <div className={` ${style.paddingLink} d-flex justify-content-center `}>
<Link className={`${style.linkStyle}  fs-3`} to="/" >{t("home")}</Link>
<a href="#about" className={`${style.linkStyle}  fs-3 px-5`} > {t("about")}</a>
<a href="#contactus" className={`${style.linkStyle}  fs-3`} > {t("contact")}</a>
    </div>
      </div>
<div className='col-md-4 '>
<p  className='fs-4 text-center '>{t("saved")}</p>
</div>

<div className='col-md-4'>
<div className={`${style.socialIcons}  d-flex justify-content-center align-items-center pt-1`}>
<a href="https://www.facebook.com/citymegagroupholding/" target="_blank">
<i className='fa fa-brands fa-facebook-f fs-3 me-3 cursor-pointer'></i>
</a>
<a href="https://www.instagram.com/cmg.citymegagroup/" target="_blank">
<i className='fa fa-brands  fa-instagram fs-3 me-3 cursor-pointer'></i>
</a>
<a href="#" target="_blank">
<i className='fa fa-brands fa-linkedin fs-4 me-3 cursor-pointer'></i>
</a>
<a href="#" target="_blank">
<i class="fa-brands fa-youtube fs-3 me-3 cursor-pointer"></i>
</a>
<a href="https://iwtsp.com/201032549172" target="_blank">
<i className='fa fa-brands fa-whatsapp fs-3 me-3 cursor-pointer'></i>
</a>

</div>
</div>

    </div>
    </div>

  </footer>
    
    </>
  )
}
