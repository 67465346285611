import React from "react";


import style from "./Home.module.css";



import SlideBar from "./SlideBar";
import Destination from "./Destination";
import Department from "./Department";
import Gps from "./Gps";
import About from "../About/About"
import ContactUs from "../ContactUs/ContactUs"


export default function Home() {

  return (
    <>
      {/* <Helmet>
        <title>{t("home")}</title>
      </Helmet> */}

      <section className={`${style.home} `}>

      <SlideBar/>

        <hr className={` ${style.hrStyle} mt-2`} />

        <div className={`my-5 ${style.container}`}>
    
      <Destination/>

        <hr className={` ${style.hrStyle} mt-3`} />
        <About/>

        <hr className={` ${style.hrStyle} mt-3`} />

      <Department/>

      <hr className={` ${style.hrStyle} mt-3`} />

      <ContactUs/>

<hr className={` ${style.hrStyle} mt-3`} />
      <Gps/>
        </div>

      </section>


    </>
  );
}

