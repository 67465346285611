import React from "react";

import style from "./About.module.css";
import logo from "../../Assets/logo/doctor.jpg"
import { Helmet } from "react-helmet";

import cmgPort from "../../Assets/vrPic/cmg-port.jpg"

import { useTranslation } from "react-i18next";
import {  Fade  } from "react-awesome-reveal";

export default function About() {
  const { t } = useTranslation();


  return (
    <>

    {/* <Helmet>
    <title>{t("about")}</title>
  </Helmet> */}


    <section className={`${style.container}  mx-auto position-relative`} id="about">

    <h1 className={`text-center mt-5 fw-bold mb-3 ${style.header}`}> {t("aboutPage.titleCompany")}</h1>

    <div className="row">


    <div className={`col-lg-3 ${style.picBorder} mt-5`}>
    <picture>
      <img
        src={cmgPort}
        className={`img-fluid ${style.picAbout}`}
        alt="image Logo"
      />
    </picture>
    </div>
   
    <div className={` col-lg-9 d-flex flex-column justify-content-center ${style.spaceBetween}`}>
    <Fade>
<p className={` fw-semibold display-5 ${style.maged}`}> {t("aboutPage.descriptionCompany")} </p>
<p className={`h2 mt-3 ${style.sizeProfile}`}>{t("aboutPage.descriptionCompany1")}</p>
<p className={`h2 mt-4 ${style.sizeProfile}`}>{t("aboutPage.descriptionCompany2")}</p>
</Fade>


  </ div>
  

  
</div>

<hr className={` ${style.hrStyle} mt-5`}/>


    <h1 className={`text-center mt-5 fw-bold mb-3 ${style.header}`}> {t("aboutPage.titleOwner")}</h1>
    <div className="row pb-4">
    <div className={`col-lg-3 ${style.picBorder} mt-5`}>
    <picture>
      <img
        src={logo}
        className={`img-fluid ${style.picAbout}`}
        alt="image Logo"
      />
    </picture>
    </div>
   
    <div className={` col-lg-9 d-flex flex-column justify-content-center  ${style.spaceBetween}`}>
    <Fade>
<p className={` fw-semibold display-5 ${style.maged}`}> {t("aboutPage.descriptionOwner")} </p>
<p className={`h2 mt-3 ${style.sizeProfile}`}>{t("aboutPage.descriptionOwner1")}</p>
<p className={`h2 mt-4 ${style.sizeProfile}`}>{t("aboutPage.descriptionOwner2")}</p>
</Fade>


  </ div>
  
  
</div>


{/* <hr className={` ${style.hrStyle} mt-5`}/> */}


    

  </section>
    </>
  );
}
