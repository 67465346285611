/* eslint-disable react/no-unknown-property */
import React from 'react'

import code from "../../Assets/QR Code/code.png"


export default function Gps() {
  return <>

  <div className=' position-relative'>
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27635.686235704456!2d31.413145934765627!3d30.02363589999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583d72652d9df7%3A0xe1d15535c63d1b3!2sCity%20Mega%20Group!5e0!3m2!1sen!2seg!4v1725356995009!5m2!1sen!2seg"
        height="450" 
        allowfullscreen="" 
        loading="lazy" 
        className=" w-100 border border-0 rounded-2"
        referrerpolicy="no-referrer-when-downgrade"
        >
        </iframe>
        <img src={code} alt="QRcode" className="position-absolute end-0 bg-white m-4"  width="150" height="150"/>

  </div>

  
  </>
}
